<template>
  <div class="flex_column">
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="title" v-if="currentItem.title">{{ currentItem.title }}</div>
      <a-button
        @click="showAnswer"
        style="width: 100%"
        type="primary"
        class="margin_top_20"
      >
        查看答案
      </a-button>
      <div v-if="answerModalVisible">
        <template v-for="itemImg in currentItem.items">
          <preview-img
            :key="itemImg.id"
            v-if="itemImg"
            :src="itemImg.option_text"
          ></preview-img>
        </template>
      </div>
    </div>
    <choose-footer
      :outerIndex="currentIndex"
      @toPrev="toPrev"
      @toNext="toNext"
      :showResetImg="currentItem.showResetImg"
      hidePrevIcon
    ></choose-footer>
  </div>
</template>

<script>
import ChooseFooter from '@/components/ChooseFooter';
import { PUBLIC_DATA } from '@/utils/common';
import PreviewImg from '@/components/PreviewImg';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ChooseFooter, PreviewImg },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    chapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgUrl,
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: [],
      answerModalVisible: false
    };
  },

  computed: {
    currentItem() {
      const defaultValue = {
        hisIndex: -1,
        book_chapter: {}
      };
      return this.allListData[this.currentIndex] || defaultValue;
    }
  },

  mounted() {
    this.allLength = this.itemList.length;
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
  },
  methods: {
    hasFinished() {
      this.$emit('endClick');
    },

    showAnswer() {
      this.answerModalVisible = true;
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        if (!item.used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    toPrev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },

    toNext() {
      const { hisIndex } = this.currentItem;
      if (hisIndex + 1 >= this.itemList.length) {
        this.hasFinished();
        return;
      }
      this.currentIndex++;
    }
  }
};
</script>

<style lang="less" scoped>
.flex_column {
  height: 100%;
  .inner_content {
    flex: 2;
    overflow-y: scroll;
    padding: 30px;
  }
}
</style>
