import { render, staticRenderFns } from "./SeeSpell.vue?vue&type=template&id=61c6cb36&scoped=true&"
import script from "./SeeSpell.vue?vue&type=script&lang=js&"
export * from "./SeeSpell.vue?vue&type=script&lang=js&"
import style0 from "./SeeSpell.vue?vue&type=style&index=0&id=61c6cb36&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c6cb36",
  null
  
)

export default component.exports