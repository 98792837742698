<template>
  <a-radio-group :value="chooseId" class="radio_outer" @change="radioChange">
    <label
      v-for="(item, index) in items"
      :key="index"
      class="radio_item"
      :style="{ color: formatColor(item.id) }"
    >
      <div class="inner_item">
        <div
          class="icon_box"
          v-if="hasConfirm && (chooseId == item.id || answer == item.id)"
        >
          <span
            v-if="imgUrl && !isChooseRightAnswer && chooseId == item.id"
            class="anticon-cuowu anticon-yyashow icon_wrong"
            mode="widthFix"
          ></span>
          <span
            v-if="hasConfirm && imgUrl && answer == item.id"
            :style="{ color: themeColor }"
            class="icon_right anticon-duihao anticon-yyashow"
          ></span>
        </div>
        <a-radio
          :value="item.id"
          v-if="formatRadio(item.id)"
          :disabled="hasConfirm"
          :color="themeColor"
          class="item_radio"
        />
        <img
          class="radio_img"
          v-if="imgUrl"
          :src="imgUrl + item.option_text"
          alt
          mode="widthFix"
        />
      </div>
    </label>
  </a-radio-group>
</template>

<script>
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA } from '../../utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    isChooseRightAnswer() {
      return (
        this.chooseId &&
        this.answer &&
        Number(this.chooseId) === Number(this.answer)
      );
    }
  },

  props: {
    answer: {
      type: [Number, String],
      default: ''
    },
    chooseId: {
      type: [Number, String],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    hasConfirm: {
      type: Boolean,
      default: false
    },
    hisId: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      imgUrl: imgUrl
    };
  },
  methods: {
    formatColor(itemId) {
      if (!this.hasConfirm) {
        return 'inherit';
      }
      if (
        Number(itemId) !== Number(this.chooseId) &&
        Number(itemId) !== Number(this.answer)
      ) {
        return 'inherit';
      }
      if (
        !this.isChooseRightAnswer &&
        Number(itemId) === Number(this.chooseId)
      ) {
        return 'red';
      }
      return this.themeColor;
    },

    formatRadio(itemId) {
      if (!this.hasConfirm) {
        return true;
      }
      if (this.isChooseRightAnswer) {
        return Number(itemId) !== Number(this.answer);
      }
      return (
        Number(this.chooseId) !== Number(itemId) &&
        Number(this.answer) !== Number(itemId)
      );
    },

    radioChange(e) {
      this.$emit('radioChange', e.target.value, this.hisId);
    }
  }
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  display: block;
  width: 100%;
  .ant-radio-wrapper {
    font-size: 16px;
  }
}

.radio_outer {
  display: flex;
  flex-wrap: wrap;
  .radio_item:nth-of-type(odd) {
    margin-right: 5%;
  }
  .inner_item {
    width: 50%;
    position: relative;
    margin: 0 auto;
  }
  .radio_item {
    display: block;
    width: 45%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    .item_radio {
      position: absolute;
      left: 0;
      opacity: 0;
    }
    .radio_img {
      width: 100%;
    }
    .item_index {
      margin-left: 10px;
      margin-right: 15px;
      vertical-align: middle;
    }
    .icon_box {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      text-align: center;
      display: flex;
      .icon_right,
      .icon_wrong {
        color: white;
        font-size: 60px;
        margin: auto;
      }
    }
  }
}
</style>
