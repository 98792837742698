<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">
      {{ currentItem.title }}
    </div>
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="card_img text_center" v-if="currentItem.word.img_url">
        <img :src="imgUrl + currentItem.word.img_url" alt="" />
      </div>
      <div class="game_title text_center margin_bottom_20">
        <template v-if="currentItem.used">
          <template v-for="item in currentItem.wordTextArr">
            <span :key="item.itemKey" :style="{ color: item.itemColor }">{{
              item.itemText
            }}</span>
          </template>
        </template>
        <template v-else>
          {{ currentItem.word.en_content }}
        </template>
      </div>
      <div v-if="currentItem.word.symbol" class="text_center margin_bottom_20">
        [{{ currentItem.word.symbol }}]
      </div>
      <div :style="{ color: themeColor }" class="text_center margin_bottom_20">
        {{ currentItem.word.cn_content }}
      </div>
      <template v-if="currentItem.word.cn_explanation">
        <div
          class="text_center"
          v-for="item in currentItem.word.cn_explanation"
          :key="item"
        >
          {{ item }}
        </div>
      </template>
    </div>
    <div style="position: relative" v-if="currentItem.used">
      <score-container :itemUsedData="currentItem.used"></score-container>
    </div>
    <footer-record
      evalType="word"
      @toPrev="toPrev"
      @toNext="toNext"
      :hisIndex="currentItem.hisIndex"
      :src="currentItem.word.audio_url"
      @startRecorder="toStartRecorder"
      @stopCountDown="stopCountDown"
    ></footer-record>
  </div>
</template>

<script>
import { saveUserGameTask } from '@/api/service';
import FooterRecord from '@/components/FooterRecord';
import globalConfig from '@/store/global_config';
import ScoreContainer from '@/components/ScoreContainer';
import {
  showMyToast,
  showMySuccess,
  PUBLIC_DATA,
  getStartRecordObj,
  getWordColorText
} from '../../../utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { FooterRecord, ScoreContainer },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    bookChapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgUrl,
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: [],
      timer: ''
    };
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    currentItem() {
      const defaultValue = {
        word: {}
      };
      return this.allListData[this.currentIndex] || defaultValue;
    },
    minScoreNumber() {
      return (
        globalConfig.state.scoreArr[2] && globalConfig.state.scoreArr[2].min
      );
    }
  },

  mounted() {
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.allLength = this.itemList.length;
    this.initRecorder();
  },

  methods: {
    initRecorder() {
      globalConfig.commit('initRecorder', {
        micFailCallback: () => {
          showMySuccess('请手动打开录音权限，在重试');
          // 麦克风被拒绝
        },
        micSuccessCallback: () => {
          // 麦克风已允许
        },

        engineFirstInitDone() {}
      });
    },

    stopCountDown() {
      globalConfig.commit('stopRecorder');
      globalConfig.commit('showLoading', '评测中');
    },

    // 去开启录音
    toStartRecorder() {
      clearTimeout(this.timer);
      this.resetCurrentItem();
      // 暂停音频
      globalConfig.commit('stopAudio');
      globalConfig.commit('startRecorder', {
        recordParams: {
          ...getStartRecordObj('word', 5000, this.currentItem.word.en_content)
        },
        getEvalMessage: this.getEvalMessage
      });
    },

    getEvalMessage(data) {
      const { id } = this.currentItem;
      const {
        result: { overall }
      } = data;
      saveUserGameTask({
        gameId: this.gameId,
        typeId: this.typeId,
        book_chapter_id: this.bookChapterId,
        word_choice_id: id,
        user_answer: '',
        read_result: data
      }).then(() => {
        globalConfig.commit('hideLoading');
        this.allListData = this.formatListData(data, id);
        this.playCurrentItemRecord(overall >= this.minScoreNumber);
        // TODO 读完替换数据方便用户查看上一题,下一题
      });
    },

    playCurrentItemRecord(needJump) {
      const {
        read_result: { audioUrl }
      } = this.currentItem.used;
      globalConfig.commit('playAudio', {
        type: 'record',
        src: audioUrl,
        //  播放完成的事件
        endCallback: needJump ? this.playReorderEnd : ''
      });
    },

    playReorderEnd() {
      this.timer = setTimeout(() => {
        this.toNext();
      }, 1000);
    },

    // 格式化当前数组
    formatListData(readResult, itemId) {
      const newOuterArr = [];
      this.allListData.forEach((item, outerIndex) => {
        newOuterArr.push(item);
        if (Number(item.id) === Number(itemId)) {
          newOuterArr[outerIndex].used = {
            read_result: readResult
          };
          newOuterArr[outerIndex].wordTextArr = getWordColorText(readResult);
        }
      });
      return newOuterArr;
    },

    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        if (!item.used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    resetCurrentItem() {
      const newArr = [];
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(item.id) === Number(this.currentItem.id)) {
          newArr[index].used = '';
          newArr[index].wordTextArr = [];
        }
      });
      this.allListData = newArr;
    },

    toPrev() {
      clearTimeout(this.timer);
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },

    toNext() {
      const { used, hisIndex } = this.currentItem;
      clearTimeout(this.timer);
      if (!used) {
        showMyToast('w', '请先完成朗读');
        return;
      }
      if (hisIndex + 1 >= this.itemList.length) {
        this.hasFinished();
        return;
      }
      this.currentIndex++;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
}
</style>
