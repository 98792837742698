<template>
  <div class="outer_content_page flex_column">
    <div class="wrapper_content" @click="hidePlayList">
      <div class="inner_content">
        <div class="bg-cover"></div>
      </div>
      <div class="title">
        <div class="music-info">
          <div class="song" id="songName">{{ currentItem.title }}</div>
          <!-- <div class="artist" id="artist"></div> -->
        </div>
      </div>
      <div class="play-board">
        <img
          class="play-needle"
          v-if="showPanel"
          :class="{
            'resume-needle': playAudioStatus,
            'pause-needle': !playAudioStatus
          }"
          id="needle"
          src="https://yyashow-1255660808.cos.ap-beijing.myqcloud.com/english-study/common/play_needle.png"
        />

        <div class="my_wrapper" ref="wrapper" v-if="false"></div>

        <div
          @change="swiperChange"
          class="swiper-container outer_swiper"
          v-if="false"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <!-- <div class="disk-bg"></div> -->
              <!-- <div
                class="disk-cover"
                :class="{
                  'disk-cover-animation-running': playAudioStatus,
                  'disk-cover-animation': !playAudioStatus
                }"
              >
                <image
                  mode="widthFix"
                  class="album"
                  src="http://p4.music.126.net/aRkTVvK4T6cDfS3o-ph06w==/7755955022950668.jpg"
                  draggable="false"
                />
                <image
                  mode="widthFix"
                  class="disk-border"
                  src="https://yyashow-1255660808.cos.ap-beijing.myqcloud.com/english-study/common/play_disc.png"
                  draggable="false"
                />
              </div>-->
            </div>
            <div class="swiper-slide" v-if="currentItem.intro">
              <div class="rich_text_container">
                <!-- <rich-text :nodes="currentItem.intro"></rich-text> -->
                <div>{{ currentItem.intro }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="aduio_footer">
          <div class="process" id="process">
            <span id="currentTime">{{ currentItem.currentTime }}</span>
            <a-slider
              :value="currentItem.current"
              class="process-bar"
              @change="sliderChange"
              :max="currentItem.allTime"
              :tooltipVisible="false"
            ></a-slider>
            <span id="totalTime">{{ currentItem.duration }}</span>
          </div>
          <div class="control" id="controls">
            <span
              v-if="allListData.length > 1"
              @click="toPrev"
              :style="{ color: themeColor }"
              class="anticon-yyashow anticon-prev1"
            ></span>
            <span
              :style="{ color: themeColor }"
              v-if="playAudioStatus"
              @click="pauseAudio"
              class="anticon-yyashow anticon-zanting2"
            ></span>
            <span
              :style="{ color: themeColor }"
              v-else
              class="anticon-yyashow anticon-bofang2"
              @click="playAudio"
            ></span>
            <span
              v-if="allListData.length > 1"
              @click="toNext"
              class="anticon-yyashow anticon-next1"
              :style="{ color: themeColor }"
            ></span>
            <span
              v-if="false"
              :style="{ color: themeColor }"
              @click.stop="showPlayList"
              class="anticon-yyashow anticon-bofangliebiao"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="play-list" id="playList">
      <div class="list-title">播放列表（{{ allLength }}）</div>
      <ul class="list-content" id="listContent">
        <li
          @click="chooseCurrentItem(lix)"
          v-for="(item, lix) in allListData"
          :key="lix"
        >
          <span
            :style="{ color: themeColor }"
            v-if="lix == itemIndex"
            class="song-play anticon-yyashow anticon-laba"
          ></span>
          <span :style="{ color: lix == itemIndex ? themeColor : 'inherit' }">
            {{ item.title }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core';
import {
  showMySuccess,
  showLoading,
  createAudioText,
  hideLoading,
  formatTimeByTime,
  handleWithUrl
} from '../../../utils/common';
import globalConfig from '@/store/global_config';
export default {
  components: {},
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    bookChapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      allLength: 0,
      allListData: [],
      currentTimeScale: 0,
      showEndPage: false,
      endPageObj: {},
      hisEnterDate: '',
      alreadyUsedData: [],
      requestUrl: 'question/get-game-task-info',
      saveProgressUrl: 'question/save-user-game-task',
      playAudioStatus: false,
      itemIndex: 0,
      currentItem: {
        duration: '00:00',
        currentTime: '00:00',
        percentProgress: '0%'
      },
      // 是否显示showPanel
      showPanel: true,
      show_animation: '',
      swiperOption: {}
    };
  },

  beforeDestroy() {
    console.log('beforeDestroy');
    this.destroyAudio();
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.scroll = new BScroll(this.$refs.wrapper, {
        scrollX: true,
        click: true
      });
    });
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.allLength = this.itemList.length;
    this.playCurrentItemAudio();
  },

  methods: {
    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    toPrev() {
      if (this.itemIndex == 0) {
        return;
      }
      this.itemIndex -= 1;
      this.playCurrentItemAudio();
    },

    toNext() {
      this.toNextChoose();
    },

    toNextChoose() {
      if (this.itemIndex + 1 >= this.allListData.length) {
        this.hasFinished();
        return;
      }
      this.itemIndex += 1;
      this.playCurrentItemAudio();
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        if (!item.used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    // 播放当前项目音频
    playCurrentItemAudio() {
      this.currentItem = this.allListData[this.itemIndex];
      this.destroyAudio();
      this.currentAudio = createAudioText();
      this.currentAudio.src = handleWithUrl(this.currentItem.file_url);
      this.currentAudio.play();
      showLoading('正在加载音频');
      this.handleWithAudioEvent();
    },

    handleWithAudioEvent() {
      this.currentAudio.onplay = () => {
        console.log('onPlay');
        hideLoading();
        this.playAudioStatus = true;
      };

      this.currentAudio.onended = () => {
        console.log('onEnded');
        this.destroyAudio();
        this.toNextChoose();
      };

      this.currentAudio.ontimeupdate = () => {
        hideLoading();
        const { duration, currentTime } = this.currentAudio;
        this.currentItem = {
          ...this.currentItem,
          percentProgress:
            (Number(currentTime / duration) * 100).toFixed(2) + '%',
          duration: formatTimeByTime(duration),
          currentTime: formatTimeByTime(currentTime),
          allTime: duration,
          current: currentTime
        };
      };
    },

    hidePlayList() {
      // const showAnimation = createAudioText();
      // this.show_animation = showAnimation.bottom('-60%').step();
    },

    // 销毁音频
    destroyAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause();
      }
      this.playAudioStatus = false;
    },

    sliderChange(event) {
      this.currentAudio.currentTime = event;
    },

    swiperChange() {},

    showPlayList() {},

    playAudio() {
      if (this.currentAudio) {
        this.currentAudio.play();
        this.playAudioStatus = true;
        return;
      }
      this.playCurrentItemAudio();
    },

    pauseAudio() {
      this.currentAudio && this.currentAudio.pause();
      this.playAudioStatus = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
}
.swiper-container {
  width: 600px;
  height: 300px;
}

.play-list {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 60%;
  max-height: 390px;
  margin: 0 auto;
  bottom: -60%;
  overflow: hidden;
  z-index: 999;
  display: flex;
  flex-direction: column;
  .list-title {
    position: relative;
    background: inherit;
    text-align: center;
    border-bottom: #ccc solid 2px;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    color: #999;
  }
  ul.list-content {
    height: 100%;
    width: 100%;
    flex: 2;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
      border-bottom: #e2dfdf solid 1px;
      color: #a0a0a0;
      font-size: 16px;
      padding: 10px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-height: 20px;
      span {
        font-size: 14px;
        overflow: hidden;
      }
    }
    .song-play {
      display: inline-block;
      width: 17px;
      height: 15px;
      margin-right: 5px;
      background-size: cover;
    }
  }
}

.wrapper_content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  .inner_content {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 110%;
    width: 110%;
    margin: -5%;
    z-index: -1;
    .bg-cover {
      // background: rgba(0, 0, 0, 0.4);
      height: 100%;
      width: 100%;
    }
  }
  .title {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 10%;
    width: 100%;
    z-index: 11;
    color: #666666;
    // background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    .music-info {
      padding: 0 20px;
      margin: 0px auto;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      .song,
      .artist {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 18px;
        margin: 8px;
        overflow: hidden;
      }
      .artist {
        font-size: 14px;
        color: #c8c0cc;
        margin-top: 4px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 8px;
      }
    }
  }

  .outer_swiper {
    width: 100%;
    height: 760px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .play-board {
    position: absolute;
    overflow: hidden;
    top: 10%;
    width: 100%;
    height: 90%;
    .play-needle {
      position: absolute;
      top: -23px;
      left: 50%;
      margin: 0px -12px;
      z-index: 10;
      width: 100px;
      transform-origin: 20px 20px;
      transition: transform 0.4s;
      -webkit-transform-origin: 20px 20px;
      -webkit-transition: -webkit-transform 0.4s;
    }
    .resume-needle {
      transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
    }
    .pause-needle {
      transform: rotateZ(-25deg);
      -webkit-transform: rotateZ(-25deg);
    }
    .disk-bg {
      width: 282px;
      height: 282px;
      position: absolute;
      left: 50%;
      top: 64px;
      margin: 0px -141px;
      // background-color: rgba(251, 251, 251, 0.08);
      border-radius: 141px;
    }

    .disk-cover-animation {
      animation: rotate-disk 20s infinite normal linear;
      animation-play-state: paused;
      -webkit-animation: rotate-disk 20s infinite normal linear;
      -webkit-animation-play-state: paused;
    }

    .disk-cover-animation-running {
      animation: rotate-disk 20s infinite normal linear;
      animation-play-state: running;
      -webkit-animation: rotate-disk 20s infinite normal linear;
      -webkit-animation-play-state: running;
    }

    @keyframes rotate-disk {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }

    @-webkit-keyframes rotate-disk {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        -webkit-transform: rotateZ(360deg);
      }
    }

    .disk-cover {
      width: 560px;
      height: 560px;
      position: absolute;
      left: 50%;
      top: 130px;
      margin: 0% -280px;
      .album {
        width: 68%;
        margin: 16%;
      }
      image {
        display: block;
        width: 100%;
      }
      .disk-border {
        position: absolute;
        top: 0px;
      }
    }
  }
  .aduio_footer {
    position: absolute;
    height: 150px;
    bottom: 0;
    width: 100%;
    color: #666;
    .process {
      width: 90%;
      height: 40px;
      margin: 0px auto;
      left: 5%;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      display: flex;
      align-items: center;
      & > span:first-child {
        margin-right: 20px;
      }
      & > span:last-child {
        margin-left: 20px;
      }
      .process-bar {
        flex: 2;
        margin: 0;
        position: relative;
      }
    }
    .control {
      text-align: center;
      width: 70%;
      left: 15%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .anticon-yyashow {
        font-size: 40px;
        &.anticon-bofangliebiao {
          font-size: 40px;
        }
        &.anticon-bofang2,
        &.anticon-zanting2 {
          font-size: 60px;
        }
      }
      .c-btn {
        width: 45px;
        height: 45px;
        margin: 8px;
        &.loop-btn {
          position: relative;
          width: 36px;
          height: 36px;
        }
        &.play {
          width: 62px;
          height: 62px;
          margin-bottom: 8px;
        }
        &.pause {
          width: 62px;
          height: 62px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
.my_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
