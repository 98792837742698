var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-radio-group',{staticClass:"radio_outer",on:{"change":_vm.radioChange}},_vm._l((_vm.items),function(item,index){return _c('label',{key:index,staticClass:"radio_item",style:({
      color: !_vm.hasConfirm
        ? 'inherit'
        : item.id != _vm.chooseId && item.id != _vm.answer
        ? 'inherit'
        : !_vm.isChooseRightAnswer && item.id == _vm.chooseId
        ? 'red'
        : _vm.activeColor
    })},[(_vm.hasConfirm && (_vm.chooseId == item.id || _vm.answer == item.id))?_c('div',{staticClass:"icon_box"},[(_vm.imgUrl && !_vm.isChooseRightAnswer && _vm.chooseId == item.id)?_c('span',{staticClass:"anticon-cuowu anticon-yyashow icon_wrong",attrs:{"mode":"widthFix"}}):_vm._e(),(_vm.hasConfirm && _vm.imgUrl && _vm.answer == item.id)?_c('span',{staticClass:"icon_right anticon-duihao anticon-yyashow",style:({ color: _vm.themeColor })}):_vm._e()]):_vm._e(),(
        !_vm.hasConfirm ||
          (_vm.isChooseRightAnswer && item.id != _vm.answer) ||
          (!_vm.isChooseRightAnswer && _vm.chooseId != item.id && _vm.answer != item.id)
      )?_c('a-radio',{staticClass:"item_radio",attrs:{"value":item.id,"disabled":_vm.hasConfirm,"color":_vm.themeColor}}):_vm._e(),(_vm.imgUrl)?_c('img',{staticClass:"radio_img",attrs:{"src":_vm.imgUrl + item.option_text,"alt":"","mode":"widthFix"}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }