<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">
      {{ currentItem.title }}
    </div>
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <audio-play
        outerClass="text_center"
        :src="currentItem.word.audio_url"
      ></audio-play>
      <div class="game_title text_center margin_bottom_20">
        {{ currentItem.word.cn_content }}
      </div>
      <div class="line_box">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="text_area" v-if="currentItem.chooseWordArr.length <= 8">
          <span
            class="choose_text"
            v-for="(chooseItem, chooseIndex) in currentItem.chooseWordArr"
            @click="wordReturnBack(chooseIndex, chooseItem)"
            :key="chooseIndex"
            :style="{
              color: inputItemColor(chooseItem, chooseIndex)
            }"
          >
            {{ chooseItem.text }}
          </span>
        </div>
        <div
          class="other_text_area"
          v-if="currentItem.chooseWordArr.length > 8"
        >
          <span
            class="other_choose_text"
            v-for="(chooseItem, chooseIndex) in currentItem.chooseWordArr"
            @click="wordReturnBack(chooseIndex, chooseItem)"
            :key="chooseIndex"
            :style="{
              color: inputItemColor(chooseItem, chooseIndex)
            }"
          >
            {{ chooseItem.text }}
          </span>
        </div>
      </div>
      <div class="word_container" v-if="!currentItem.showResetImg">
        <span
          class="word_text"
          :style="{ backgroundColor: themeColor }"
          v-for="(item, index) in currentItem.wordArr"
          :key="index"
          @click="chooseCurrentText(currentItem, item, index)"
        >
          {{ item }}
        </span>
      </div>
      <div style="height:1000px"></div>
    </div>
    <choose-footer
      :outerIndex="currentIndex"
      @toPrev="toPrev"
      @toNext="toNext"
      @resetItem="resetCurrentItem"
      :showResetImg="currentItem.showResetImg"
    ></choose-footer>
  </div>
</template>

<script>
import { saveUserGameTask } from '@/api/service';
import AudioPlay from '@/components/AudioPlay';
import ChooseFooter from '@/components/ChooseFooter';
import { showMyToast, showMySuccess, shuffle } from '../../../utils/common';
import globalConfig from '@/store/global_config';
export default {
  components: { ChooseFooter, AudioPlay },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    bookChapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: [],
      currentInputText: ''
    };
  },

  computed: {
    currentItem() {
      const defaultValue = {
        items: [],
        word: {},
        chooseWordArr: [],
        showResetImg: false
      };
      return this.allListData[this.currentIndex] || defaultValue;
    },
    chooseTextSize() {
      return this.currentItem.chooseWordArr.length <= 14 ? '80px' : '50px';
    },
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.currentInputText = this.allListData[0].answer.split('')[0];
    this.allLength = this.itemList.length;
  },
  methods: {
    // 获取它的颜色值
    inputItemColor(inputItem, inputIndex) {
      const { hasConfirmClick, answerArr } = this.currentItem;
      if (!hasConfirmClick) {
        return 'inherit';
      }
      if (answerArr[inputIndex] !== inputItem.text) {
        return 'red';
      }
      return this.themeColor;
    },

    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        const { answer, id, used } = item;
        const replaceAnswerEmpty = answer.replace(/\s+/g, '');
        if (!used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++,
            wordArr: this.handleRandomArr(replaceAnswerEmpty.split('')),
            chooseWordArr: this.handleProductArr(replaceAnswerEmpty.split('')),
            hisId: id,
            answerArr: replaceAnswerEmpty.split('')
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    handleRandomArr(word) {
      const newArr = shuffle(word);
      if (newArr.join('') === word) {
        this.handleRandomArr(word);
        return;
      }
      return newArr;
    },

    handleProductArr(arr) {
      const resultArr = [];
      arr.forEach(() => {
        resultArr.push({
          text: ''
        });
      });
      return resultArr;
    },

    haveChooseAllWord(arr) {
      let isAccess = true;
      arr.forEach(item => {
        if (!item || !item.text) {
          isAccess = false;
        }
      });
      return isAccess;
    },

    hanleFormatObjToString(arr = []) {
      const newArr = [];
      arr.forEach(item => {
        newArr.push(item.text);
      });
      return newArr.join('');
    },

    confirmBtn() {
      const { chooseWordArr: stuAnswer, showResetImg, id } = this.currentItem;
      if (showResetImg) {
        showMyToast('w', '请重新作答');
        return;
      }
      const isFinishedCurrentChoose = this.haveChooseAllWord(stuAnswer);
      if (!isFinishedCurrentChoose) {
        showMyToast('w', '请完成作答');
        return;
      }
      const formatStuAnswer = this.hanleFormatObjToString(stuAnswer);
      saveUserGameTask({
        typeId: this.typeId,
        gameId: this.gameId,
        book_chapter_id: this.bookChapterId,
        word_choice_id: id,
        user_answer: formatStuAnswer
      }).then(response => {
        const isRightAnswer = Number(response.data.is_right) === 1;
        this.confirmInputText();
        // 错误
        if (isRightAnswer) {
          this.timer = setTimeout(() => {
            this.toNext();
          }, 500);
          return;
        }
      });
    },

    chooseCurrentText(parentItem, item, index) {
      const newArr = [];
      const { id: hisId, chooseWordArr, wordArr } = parentItem;
      this.allListData.forEach((outerItem, outerIndex) => {
        newArr.push(outerItem);
        if (Number(outerItem.id) === Number(hisId)) {
          const firstEmptyIndex = this.handleFindWordArrFirstEmptyIndex(
            chooseWordArr
          );
          newArr[outerIndex].chooseWordArr[firstEmptyIndex] = {
            text: item,
            hisOriginIndex: index
          };
          newArr[outerIndex].wordArr = this.handleEmptyCurrentIndexItem(
            wordArr,
            index
          );
        }
      });
      this.allListData = newArr;
    },

    handleFindWordArrFirstEmptyIndex(arr = []) {
      let isFirst = true;
      let firstIndex = 0;
      arr.forEach((item, index) => {
        if (isFirst && !item.text) {
          isFirst = false;
          firstIndex = index;
        }
      });
      return firstIndex;
    },

    handleEmptyCurrentIndexItem(arr = [], outerIndex) {
      const newArr = [];
      arr.forEach((item, index) => {
        newArr.push(item);
        if (Number(index) === Number(outerIndex)) {
          newArr[outerIndex] = '';
        }
      });
      return newArr;
    },

    // 返回原位置
    wordReturnBack(chooseItemIndex, choosItem) {
      const {
        hisId,
        chooseWordArr,
        wordArr,
        hasConfirmClick
      } = this.currentItem;
      const { text, hisOriginIndex } = choosItem;
      if (hasConfirmClick) {
        return;
      }
      const newArr = [];
      this.allListData.forEach((currentItem, currentIndex) => {
        newArr.push(currentItem);
        if (Number(currentItem.hisId) === Number(hisId)) {
          newArr[currentIndex].chooseWordArr = this.handleEmptyCurrentIndexItem(
            chooseWordArr,
            chooseItemIndex
          );
          newArr[currentIndex].wordArr = this.handleReturnHisItem(
            wordArr,
            hisOriginIndex,
            text
          );
        }
      });
      this.allListData = newArr;
    },

    handleReturnHisItem(arr = [], originIndex, wordText) {
      const newArr = [];
      arr.forEach((item, index) => {
        newArr.push(item);
        if (Number(index) === Number(originIndex)) {
          newArr[index] = wordText;
        }
      });
      return newArr;
    },

    confirmInputText() {
      const { id: hisId } = this.currentItem;
      const newArr = [];
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(hisId) === Number(item.id)) {
          newArr[index].showResetImg = true;
          newArr[index].hasConfirmClick = true;
        }
      });
      this.allListData = newArr;
    },

    resetCurrentItem() {
      const newArr = [];
      const { answer, id } = this.currentItem;
      const answerArr = answer.split('');
      this.allListData.forEach((item, outerIndex) => {
        newArr.push(item);
        if (Number(item.id) === Number(id)) {
          newArr[outerIndex].showResetImg = false;
          newArr[outerIndex].hasConfirmClick = false;
          newArr[outerIndex].chooseWordArr = this.handleProductArr(answerArr);
          newArr[outerIndex].wordArr = shuffle(answerArr);
        }
      });
      this.allListData = newArr;
    },

    toPrev() {
      clearTimeout(this.timer);
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    toNext() {
      clearTimeout(this.timer);
      const { hisIndex, hasConfirmClick } = this.currentItem;
      if (!hasConfirmClick) {
        this.confirmBtn();
        return;
      }
      if (hisIndex + 1 >= this.itemList.length) {
        this.hasFinished();
        return;
      }
      this.currentIndex++;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.outer_content_page {
  overflow: hidden;
  .inner_content {
    padding: 30px;
    flex: 2;
    max-height: 100%;
    overflow-y: scroll;
    .line_box {
      width: 80%;
      margin: 0 auto;
      border-top: solid 1px black;
      border-bottom: solid 1px black;
      height: 100px;
      position: relative;
      text-align: center;
      .line {
        height: 24px;
        border-bottom: dashed 1px lightgray;
      }
      .other_text_area {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        font-size: 70px;
        .other_choose_text {
          margin-right: 10rpx;
        }
      }
      .text_area {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        .choose_text {
          font-size: 90px;
          margin-right: 10px;
        }
      }
    }
    .word_container {
      margin: 0 auto;
      margin-top: 80px;
      font-size: 40px;
      width: 80%;
      .word_text {
        margin-right: 25px;
        border-radius: 8px;
        padding: 0 15px;
        margin-bottom: 10px;
        color: white;
        display: inline-block;
      }
    }
  }
}
.bottom_container {
  left: 10%;
  width: 80%;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;
}
</style>
