<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">
      {{ currentItem.title }}
    </div>
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="game_title text_center margin_bottom_20">
        {{ currentItem.word.cn_content }}
      </div>
      <div v-if="currentItem.word.symbol" class="text_center margin_bottom_20">
        [{{ currentItem.word.symbol }}]
      </div>
      <div class="line_box">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="text_area" @click="toggleShowKeyBoard">
          <template v-if="!currentItem.hasConfirmClick">
            <div class="text_input" :style="{ fontSize: chooseTextSize }">
              {{ currentInputText }}
            </div>
          </template>

          <template v-else>
            <template v-if="currentItem.inputValueArr">
              <div class="text_container" :style="{ fontSize: chooseTextSize }">
                <span
                  v-for="(inputItem, inputIndex) in currentItem.inputValueArr"
                  :key="inputIndex"
                  :style="{
                    color: inputItemColor(inputItem, inputIndex)
                  }"
                >
                  {{ inputItem }}
                </span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="bottom_container" v-if="keyBoardStatus">
      <my-key-board
        :initText="currentInputText"
        @input="inputChange"
        @done="inputDone"
        @delete="inputDelete"
      ></my-key-board>
    </div>
    <choose-footer
      v-else
      :outerIndex="currentIndex"
      @toPrev="toPrev"
      @toNext="toNext"
      @resetItem="resetCurrentItem"
      :showResetImg="currentItem.showResetImg"
    ></choose-footer>
  </div>
</template>

<script>
import { saveUserGameTask } from '@/api/service';
import MyKeyBoard from '@/components/MyKeyBoard';
import ChooseFooter from '@/components/ChooseFooter';
import { showMyToast, showMySuccess } from '../../../utils/common';
import globalConfig from '@/store/global_config';
export default {
  components: { ChooseFooter, MyKeyBoard },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    bookChapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: [],
      keyBoardStatus: false,
      currentInputText: ''
    };
  },

  computed: {
    currentItem() {
      const defaultValue = {
        items: [],
        word: {},
        chooseWordArr: []
      };
      return this.allListData[this.currentIndex] || defaultValue;
    },
    chooseTextSize() {
      return this.currentItem.chooseWordArr.length <= 14 ? '80px' : '50px';
    },
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.currentInputText = this.allListData[0].answer.split('')[0];
    this.allLength = this.itemList.length;
  },
  methods: {
    // 获取它的颜色值
    inputItemColor(inputItem, inputIndex) {
      const { answerArr } = this.currentItem;
      if (!inputItem || !answerArr[inputIndex]) {
        return 'red';
      }
      if (answerArr[inputIndex].toLowerCase() !== inputItem.toLowerCase()) {
        return 'red';
      }
      return this.themeColor;
    },

    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },
    // 显示键盘
    toggleShowKeyBoard() {
      this.keyBoardStatus = !this.keyBoardStatus;
    },

    /**
     * 配合键盘输入事件：input，获取输入内容到虚拟输入框中
     */
    inputChange(e) {
      this.currentInputText = this.currentInputText + e;
    },

    inputDelete() {
      if (this.currentInputText.length < 1) {
        return;
      }
      const keyboardValue = this.currentInputText.substring(
        0,
        this.currentInputText.length - 1
      );
      this.currentInputText = keyboardValue;
    },

    inputDone() {
      this.confirmBtn();
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        const { answer, id, used } = item;
        if (!used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++,
            chooseWordArr: this.handleProductArr(answer.split('')),
            hisId: id,
            answerArr: answer.split('')
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    handleProductArr(arr) {
      const resultArr = [];
      arr.forEach(() => {
        resultArr.push({
          text: ''
        });
      });
      return resultArr;
    },

    confirmBtn() {
      if (!this.currentInputText) {
        showMyToast('w', '请填写单词');
        return;
      }
      const currrentUserInputValue = this.currentInputText.trim();
      saveUserGameTask({
        typeId: this.typeId,
        gameId: this.gameId,
        book_chapter_id: this.bookChapterId,
        word_choice_id: this.currentItem.id,
        user_answer: currrentUserInputValue
      }).then(response => {
        const isRightAnswer = Number(response.data.is_right) === 1;
        this.keyBoardStatus = false;
        this.confirmInputText(currrentUserInputValue);
        // 错误
        if (isRightAnswer) {
          this.timer = setTimeout(() => {
            this.toNext();
          }, 500);
          return;
        }
      });
    },

    confirmInputText(inputText) {
      const { id: hisId } = this.currentItem;
      const newArr = [];
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(hisId) === Number(item.id)) {
          newArr[index].showResetImg = true;
          newArr[index].hasConfirmClick = true;
          newArr[index].inputValueArr = inputText.split('');
        }
      });
      this.allListData = newArr;
    },

    resetCurrentItem() {
      const newArr = [];
      this.currentInputText = this.currentItem.answer.split('')[0];
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(item.id) === Number(this.currentItem.id)) {
          newArr[index].showResetImg = false;
          newArr[index].hasConfirmClick = false;
          newArr[index].inputValueArr = '';
        }
      });
      this.allListData = newArr;
    },

    toPrev() {
      clearTimeout(this.timer);
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    toNext() {
      clearTimeout(this.timer);
      const { hisIndex, hasConfirmClick } = this.currentItem;
      if (!hasConfirmClick) {
        this.confirmBtn();
        return;
      }
      if (hisIndex + 1 >= this.itemList.length) {
        this.hasFinished();
        return;
      }
      this.currentIndex++;
      this.currentInputText = this.currentItem.answer.split('')[0];
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.outer_content_page {
  overflow: hidden;
  .inner_content {
    padding: 30px;
    flex: 2;
    max-height: 100%;
    overflow-y: scroll;
    .line_box {
      width: 80%;
      margin: 0 auto;
      border-top: solid 1px black;
      border-bottom: solid 1px black;
      height: 100px;
      position: relative;
      text-align: center;
      .line {
        height: 24px;
        border-bottom: dashed 1px lightgray;
      }
      .text_area {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        .text_input {
          font-size: 50px;
          height: 100px;
          line-height: 100px;
        }
      }
    }
  }
}
.bottom_container {
  left: 10%;
  width: 80%;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;
}
</style>
