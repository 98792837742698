<template>
  <div class="flex_column">
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="title" v-if="currentItem.title">{{ currentItem.title }}</div>
      <a-input
        v-model="currentItem.user_answer"
        class="margin_top_20"
        placeholder="请输入答案"
      />
    </div>
    <choose-footer
      :outerIndex="currentIndex"
      hidePrevIcon
      @toPrev="toPrev"
      @toNext="toNext"
      :showResetImg="currentItem.showResetImg"
    ></choose-footer>
  </div>
</template>

<script>
import ChooseFooter from '@/components/ChooseFooter';
import { showError, showLoading, hideLoading } from '@/utils/common';
import globalConfig from '@/store/global_config';
export default {
  components: { ChooseFooter },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    chapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: []
    };
  },

  computed: {
    currentItem() {
      const defaultValue = {
        hisIndex: -1,
        book_chapter: {}
      };
      return this.allListData[this.currentIndex] || defaultValue;
    }
  },

  mounted() {
    this.allLength = this.itemList.length;
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    globalConfig.commit('changeFirstQuestion', !this.itemList[0].used);
  },
  methods: {
    // 已经完成
    hasFinished() {
      this.$emit('endClick');
    },
    // 格式化使用过的数据
    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        if (!item.used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },
    // 上一题
    toPrev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    // 下一题
    toNext() {
      const { hisIndex, user_answer, id: question_id } = this.currentItem;
      if (!user_answer) {
        showError('请先输入答案');
        return;
      }
      if (this.isRequesting) {
        return;
      }
      this.isRequesting = false;
      showLoading();
      globalConfig
        .dispatch('submitUnitUserAnswer', {
          book_chapter_id: this.chapterId,
          book_id: this.bookId,
          gameId: this.gameId,
          typeId: this.typeId,
          question_id,
          user_answer: String(user_answer).trim()
        })
        .then(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          if (hisIndex + 1 >= this.itemList.length) {
            this.hasFinished();
            this.isRequesting = false;
            return;
          }
          this.currentIndex++;
          this.isRequesting = false;
        })
        .catch(() => {
          hideLoading();
          this.isRequesting = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.flex_column {
  height: 100%;
  .inner_content {
    flex: 2;
    overflow-y: scroll;
    padding: 30px;
    .title {
      margin-bottom: 10px;
    }
  }
}
</style>
