<template>
  <div class="footer">
    <div class="prev_ques">
      <div
        v-if="outerIndex !== 0"
        @click="prevBtn"
        :style="{ backgroundColor: themeColor }"
        class="prev_text"
      >
        <span class="anticon-yyashow anticon-shangyiti sideIcon color_white"></span>
        <span class="side_text">{{ ' ' }}prev</span>
      </div>
    </div>
    <div v-if="!showResetImg" class="img_box">
      <span
        @click="playAudio"
        v-if="!playAudioStatus"
        class="anticon-bofang anticon-yyashow play"
      ></span>
      <span
        @click="stopPlayAudio"
        v-if="playAudioStatus"
        :style="{ color: themeColor }"
        class="anticon-zanting anticon-yyashow play"
      ></span>
    </div>

    <div
      class="reset"
      :style="{ color: 'white', backgroundColor: themeColor }"
      v-if="showResetImg"
      @click="resetCurrentItem"
    >
      重做
    </div>
    <div class="next_ques">
      <div :style="{ backgroundColor: themeColor }" class="next_text" @click="toNextChoose">
        <span class="side_text">next{{ ' ' }}</span>
        <span class="anticon-yyashow anticon-xiayiti sideIcon color_white"></span>
      </div>
    </div>
  </div>
</template>

<script>
import globalConfig from '../../store/global_config';
import { handleWithUrl } from '../../utils/common';
export default {
  props: {
    outerIndex: {
      type: [Number, String],
      default: 0
    },
    showResetImg: {
      type: Boolean,
      showResetImg: false
    },
    hasConfirmClick: {
      type: Boolean,
      showResetImg: false
    },
    allLength: {
      type: [Number, String],
      default: 0
    },
    src: {
      type: String,
      default: ''
    }
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    playAudioStatus() {
      return globalConfig.state.playAudioStatus;
    }
  },
  methods: {
    playAudio() {
      globalConfig.commit('playAudio', {
        src: handleWithUrl(this.src)
      });
    },

    stopPlayAudio() {
      globalConfig.commit('stopAudio');
    },

    toNextChoose() {
      this.$emit('toNext');
    },
    prevBtn() {
      this.$emit('toPrev');
    },
    resetCurrentItem() {
      this.$emit('resetItem');
    }
  }
};
</script>

<style lang="less" scoped>
.antionc-yyashow {
  font-size: 60px;
}
.footer {
  height: 70px;
  line-height: 70rpx;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .reset {
    width: 50px;
    height: 50px;
    background-color: lightgray;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
  }
  .prev_ques {
    width: 60px;
    height: 30px;
    text-align: center;

    .prev_text {
      line-height: 30px;
      font-size: 0.77rem;
      width: 60px;
      height: 30px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      color: white;
    }
  }
  .next_ques {
    width: 60px;
    height: 30px;
    text-align: center;
    .next_text {
      line-height: 30px;
      font-size: 0.77em;
      width: 60px;
      height: 30px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      color: white;
    }
  }
  .side_text {
    vertical-align: top;
  }
  .play {
    font-size: 60px;
  }
}
</style>
