<template>
  <a-radio-group class="radio_outer" @change="radioChange">
    <label
      :style="{
        color: !hasConfirm
          ? 'inherit'
          : item.id != chooseId && item.id != answer
          ? 'inherit'
          : !isChooseRightAnswer && item.id == chooseId
          ? 'red'
          : activeColor
      }"
      v-for="(item, index) in items"
      :key="index"
      class="radio_item"
    >
      <div
        class="icon_box"
        v-if="hasConfirm && (chooseId == item.id || answer == item.id)"
      >
        <span
          v-if="imgUrl && !isChooseRightAnswer && chooseId == item.id"
          class="anticon-cuowu anticon-yyashow icon_wrong"
          mode="widthFix"
        ></span>
        <span
          v-if="hasConfirm && imgUrl && answer == item.id"
          :style="{ color: themeColor }"
          class="icon_right anticon-duihao anticon-yyashow"
        ></span>
      </div>
      <a-radio
        :value="item.id"
        v-if="
          !hasConfirm ||
            (isChooseRightAnswer && item.id != answer) ||
            (!isChooseRightAnswer && chooseId != item.id && answer != item.id)
        "
        :disabled="hasConfirm"
        :color="themeColor"
        class="item_radio"
      />
      <img
        class="radio_img"
        v-if="imgUrl"
        :src="imgUrl + item.option_text"
        alt
        mode="widthFix"
      />
    </label>
  </a-radio-group>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.radio_outer {
  text-align: left;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  .radio_item:nth-of-type(odd) {
    margin-right: 5%;
  }
  .radio_item {
    display: block;
    width: 45%;
    margin-top: 50rpx;
    display: flex;
    align-items: center;
    position: relative;
    .item_radio {
      position: absolute;
      left: 0;
      opacity: 0;
    }
    .radio_img {
      width: 100%;
    }
    .item_index {
      margin-left: 20rpx;
      margin-right: 15rpx;
      vertical-align: middle;
    }
    .icon_box {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      text-align: center;
      display: flex;
      .icon_right,
      .icon_wrong {
        color: white;
        font-size: 60rpx;
        margin: auto;
      }
    }
  }
}
</style>
