<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">
      {{ currentItem.title }}
    </div>
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="game_title text_center margin_bottom_20">
        {{ currentItem.word.en_content }}
      </div>
      <div v-if="currentItem.word.symbol" class="text_center margin_bottom_20">
        [{{ currentItem.word.symbol }}]
      </div>
      <play-radio-img
        :hasConfirm="currentItem.hasConfirmClick"
        :chooseId="currentItem.chooseId"
        :answer="currentItem.answer"
        :items="currentItem.items"
        :hisId="currentItem.id"
        @radioChange="radioChange"
      ></play-radio-img>
    </div>
    <choose-footer
      :outerIndex="currentIndex"
      @toPrev="toPrev"
      @toNext="toNext"
      @resetItem="resetCurrentItem"
      :showResetImg="currentItem.showResetImg"
    ></choose-footer>
  </div>
</template>

<script>
import { saveUserGameTask } from '@/api/service';
import PlayRadioImg from '@/components/PlayRadioImg';
import ChooseFooter from '@/components/ChooseFooter';
import { showMyToast, showMySuccess } from '../../../utils/common';
export default {
  components: { PlayRadioImg, ChooseFooter },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    bookChapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: []
    };
  },

  computed: {
    currentItem() {
      const defaultValue = {
        items: [],
        word: {}
      };
      return this.allListData[this.currentIndex] || defaultValue;
    }
  },

  mounted() {
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.allLength = this.itemList.length;
  },
  methods: {
    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        if (!item.used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    radioChange(targetValue, itemId) {
      // 提交
      this.confirmBtn(targetValue, itemId);
    },

    confirmBtn(targetValue, itemId) {
      if (!targetValue) {
        showMyToast('w', '请至少选择一项');
        return;
      }
      saveUserGameTask({
        typeId: this.typeId,
        gameId: this.gameId,
        book_chapter_id: this.bookChapterId,
        word_choice_id: itemId,
        user_answer: String(targetValue)
      }).then(() => {
        const isRightAnswer =
          Number(this.currentItem.answer) === Number(targetValue);
        this.allListData = this.formatListData(
          targetValue,
          itemId,
          isRightAnswer
        );
        if (isRightAnswer) {
          setTimeout(() => {
            this.toNext();
          }, 500);
        }
      });
    },

    formatListData(chooeValue, itemId, isRightAnswer) {
      const newOuterArr = [];
      this.allListData.forEach((item, outerIndex) => {
        newOuterArr.push(item);
        if (Number(item.id) === Number(itemId)) {
          newOuterArr[outerIndex].hasConfirmClick = true;
          newOuterArr[outerIndex].chooseId = chooeValue;
          newOuterArr[outerIndex].showResetImg = !isRightAnswer;
        }
      });
      return newOuterArr;
    },

    resetCurrentItem() {
      const newArr = [];
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(item.id) === Number(this.currentItem.id)) {
          newArr[index].hasConfirmClick = false;
          newArr[index].hasChooseRadio = false;
          newArr[index].showResetImg = false;
          newArr[index].chooseId = '';
        }
      });
      this.allListData = newArr;
    },

    toPrev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    toNext() {
      const { hasConfirmClick, hisIndex } = this.currentItem;
      if (!hasConfirmClick) {
        showMyToast('w', '请先完成作答');
        return;
      }
      if (hisIndex + 1 >= this.itemList.length) {
        this.hasFinished();
        return;
      }
      this.currentIndex++;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
}
</style>
