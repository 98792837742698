<template>
  <div>
    <div class="replay_box" style="margin-top:40rpx">
      <span
        @click="restartAutoPlay"
        :style="{ color: themeColor }"
        class="anticon-yyashow anticon-replay"
      >
        replay
      </span>
    </div>
    <a-radio-group class="radio_outer" @change="radioChange" :value="chooseId">
      <label
        :style="{ color: formatColor(item.id) }"
        v-for="(item, index) in newItems"
        class="radio_item"
        :key="index"
      >
        <div class="radio_box">
          <span
            v-if="
              hasConfirm &&
                imgUrl &&
                !isChooseRightAnswer &&
                chooseId == item.id
            "
            class="anticon-cuowu anticon-yyashow icon_img"
            mode="widthFix"
          ></span>
          <span
            v-if="hasConfirm && imgUrl && answer == item.id"
            :style="{ color: themeColor }"
            class="icon_img anticon-duihao anticon-yyashow"
          ></span>
          <a-radio
            v-if="formatRadio(item.id)"
            :value="item.id"
            :disabled="hasConfirm"
            :color="themeColor"
          />
          <span
            v-if="!item.playAudioStatus"
            @click.prevent="playAudio(item.option_text, index, 'click')"
            class="anticon-yyashow anticon-bofang"
          ></span>
          <span
            :style="{ color: themeColor }"
            v-else
            @click.prevent="stopAudio(index)"
            class="anticon-yyashow anticon-zanting"
          ></span>
        </div>
      </label>
    </a-radio-group>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import {
  showError,
  PUBLIC_DATA,
  createAudioText,
  handleWithUrl
} from '@/utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  // 父组件false时候走了这个生命周期
  beforeDestroy() {
    this.currentPlayType = '';
    this.timer && clearTimeout(this.timer);
    this.currentAudio && this.currentAudio.pause();
  },

  mounted() {
    this.timer && clearTimeout(this.timer);
    if (this.isCanPlay) {
      setTimeout(() => {
        this.restartAutoPlay();
      }, 600);
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      currentPlayType: '',
      currentAudioIndex: 0,
      newItems: []
    };
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    isChooseRightAnswer() {
      return (
        this.chooseId &&
        this.answer &&
        Number(this.chooseId) === Number(this.answer)
      );
    }
  },

  watch: {
    items(newValue) {
      this.newItems = newValue;
    }
  },
  props: {
    answer: {
      type: [Number, String],
      default: ''
    },
    chooseId: {
      type: [Number, String],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    hasConfirm: {
      type: Boolean,
      default: false
    },
    isCanPlay: {
      type: Boolean,
      default: false
    },
    hisId: {
      type: [Number, String],
      default: ''
    }
  },

  onUnload() {
    this.stopAudio();
  },

  methods: {
    formatColor(itemId) {
      if (!this.hasConfirm) {
        return 'inherit';
      }
      if (
        Number(itemId) !== Number(this.chooseId) &&
        Number(itemId) !== Number(this.answer)
      ) {
        return 'inherit';
      }
      if (
        !this.isChooseRightAnswer &&
        Number(itemId) === Number(this.chooseId)
      ) {
        return 'red';
      }
      return this.themeColor;
    },

    formatRadio(itemId) {
      if (!this.hasConfirm) {
        return true;
      }
      if (this.isChooseRightAnswer) {
        return Number(itemId) !== Number(this.answer);
      }
      return (
        Number(this.chooseId) !== Number(itemId) &&
        Number(this.answer) !== Number(itemId)
      );
    },

    restartAutoPlay() {
      this.currentPlayType = 'auto';
      this.currentAudioIndex = 0;
      this.playCurrentItemAudio();
    },

    playCurrentItemAudio() {
      if (this.newItems[this.currentAudioIndex]) {
        this.playAudio(
          this.newItems[this.currentAudioIndex].option_text,
          this.currentAudioIndex,
          'auto'
        );
      }
    },
    handleWithAudioEvent() {
      this.currentAudio.onended = () => {
        this.currentAudioIndex += 1;
        if (this.currentAudioIndex >= this.newItems.length) {
          this.stopAudio();
          return;
        }
        if (this.currentPlayType === 'auto') {
          this.timer = setTimeout(() => {
            this.playCurrentItemAudio();
          }, 1000);
          return;
        }
        this.stopAudio();
      };
      this.currentAudio.onerror = () => {
        this.stopAudio();
        showError('播放错误');
      };
    },
    radioChange(e) {
      this.timer && clearTimeout(this.timer);
      this.stopAudio();
      this.$emit('radioChange', e.target.value, this.hisId);
    },

    resetItemStatus(index) {
      const newArr = [];
      this.newItems.forEach((item, hisIndex) => {
        newArr.push(item);
        if (Number(index) === Number(hisIndex)) {
          newArr[hisIndex].playAudioStatus = true;
        } else {
          newArr[hisIndex].playAudioStatus = false;
        }
      });
      this.newItems = newArr;
    },

    playAudio(audioSrc, index, type) {
      this.currentAudio && this.currentAudio.pause();
      this.currentAudio = createAudioText();
      this.currentAudio.src = handleWithUrl(audioSrc);
      this.currentAudio.play();
      this.resetItemStatus(index);
      this.currentPlayType = type;
      this.handleWithAudioEvent();
    },

    stopAudio() {
      this.currentPlayType = '';
      this.currentAudio && this.currentAudio.pause();
      this.handleResetItemStatus();
    },

    handleResetItemStatus() {
      this.resetItemStatus(this.newItems.length + 1);
    }
  }
};
</script>

<style lang="less" scoped>
.anticon-yyashow {
  color: #9da4aa;
}
.anticon-bofang,
.anticon-zanting {
  font-size: 50px;
}
.anticon-replay {
  font-size: 20px;
}
.replay_box {
  text-align: center;
}
.radio_outer {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  .radio_item {
    width: 50%;
    display: flex;
    .radio_box {
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
    .item_index {
      margin-left: 20px;
      margin-right: 15px;
      vertical-align: middle;
    }
    .icon_img {
      vertical-align: middle;
    }
  }
}
</style>
