<template>
  <!--pages/vehicleKeyboard/vehicleKeyboard.wxml-->
  <!--键盘-->
  <div class="keyboard" @click="stopProp">
    <!--数字键盘 start-->
    <template v-if="isNum">
      <template v-for="(itemName, idx) in keyboardNumber">
        <template v-if="idx < 20">
          <div
            :key="itemName"
            class="td td_num"
            @click="tapKeyboard(itemName)"
            :dataindex="idx"
            hover-class="board_bg"
            hover-start-time="0"
            hover-stay-time="80"
          >
            {{ itemName }}
          </div>
        </template>
        <template v-else>
          <div
            :key="itemName"
            class="td td_mid"
            @click="tapKeyboard(itemName)"
            :dataIndex="idx"
            hover-class="board_bg"
            hover-start-time="0"
            hover-stay-time="80"
          >
            {{ itemName }}
          </div>
        </template>
      </template>
    </template>
    <!-- 数字键盘 end -->

    <!--字母键盘-->
    <template v-if="!isNum">
      <template v-for="(itemName, idx) in keyboardAlph">
        <template v-if="idx == 10">
          <div
            :key="itemName"
            class="td td_num td_big_left"
            hover-class="board_bg"
            hover-start-time="0"
            hover-stay-time="80"
          >
            <div @click="tapKeyboard(itemName)" :dataIndex="idx">{{ itemName }}</div>
          </div>
        </template>
        <template v-else-if="idx == 18">
          <div
            :key="itemName"
            class="td td_num td_big_right"
            hover-class="board_bg"
            hover-start-time="0"
            hover-stay-time="80"
          >
            <div @click="tapKeyboard(itemName)" :dataIndex="idx">{{ itemName }}</div>
          </div>
        </template>
        <template v-else>
          <div
            :key="itemName"
            class="td td_num"
            hover-class="board_bg"
            hover-start-time="0"
            hover-stay-time="80"
          >
            <div @click="tapKeyboard(itemName)" :dataIndex="idx">{{ itemName }}</div>
          </div>
        </template>
      </template>
    </template>

    <!--删除 start-->
    <div
      class="td td_spec_del iconfont icon-deleteicon"
      @click="tapKeyboard('del')"
      hover-class="board_bg_spec"
      hover-start-time="0"
      hover-stay-time="80"
    >
      <img data-val="del" class="icon-del" src="./images/icon_del.svg" />
      <img data-val="del" class="icon-del-hover" src="./images/icon_del_press.svg" />
    </div>
    <!--删除按钮 end-->

    <!--shift start-->
    <div
      class="td td_spec_shift iconfont icon-deleteicon"
      :class="{ on: isUpperCase }"
      @click="onShift"
      hover-class="board_bg_spec"
      hover-start-time="0"
      hover-stay-time="80"
    >
      <img class="icon-shift" src="./images/icon_shift.svg" />
      <img class="icon-shift-on" src="./images/icon_shift_on.svg" />
    </div>
    <!--shift end-->

    <!-- 功能键盘 start -->
    <div class="fnkey-wrap">
      <div
        class="td trans"
        :hidden="isNum"
        @click="onTrans"
        hover-start-time="0"
        hover-stay-time="80"
      >
        123
      </div>
      <div
        class="td trans"
        :hidden="!isNum"
        @click="onTrans"
        hover-start-time="0"
        hover-stay-time="80"
      >
        ABC
      </div>
      <div
        class="td space"
        hover-class="board_bg"
        hover-start-time="0"
        hover-stay-time="80"
        @click="tapKeyboard(' ')"
      >
        space
      </div>
      <div
        class="td done"
        hover-class="board_bg"
        hover-start-time="0"
        hover-stay-time="80"
        @click="onDone"
      >
        提交
      </div>
    </div>
    <!-- 功能键盘 end -->
  </div>
</template>

<script>
export default {
  props: {
    initText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isNum: false,
      isUpperCase: false,
      keyboardNumber: '1234567890-/:;()$&@".,?!\'',
      keyboardAlph: 'qwertyuiopasdfghjklzxcvbnm',
      keyboardValue: this.initText
    };
  },
  destroyed() {
    this.keyboardValue = '';
  },

  methods: {
    /**
     * 点击键盘按键事件
     */
    tapKeyboard(tapVal) {
      // 点击删除
      if (tapVal === 'del') {
        this.$emit('delete');
        return;
      }
      this.$emit('input', tapVal);
    },

    onShift() {
      const keyboardLowerCaseAlph = 'qwertyuiopasdfghjklzxcvbnm';
      const keyboardUpperCaseAlph = 'QWERTYUIOPASDFGHJKLZXCVBNM';
      const isUpperCase = this.isUpperCase;
      let keyboardAlph;
      if (isUpperCase) {
        keyboardAlph = keyboardLowerCaseAlph;
      } else {
        keyboardAlph = keyboardUpperCaseAlph;
      }
      this.keyboardAlph = keyboardAlph;
      this.isUpperCase = !isUpperCase;
    },

    onDone() {
      this.$emit('done');
    },

    onTrans() {
      const isNum = this.isNum;
      this.isNum = !isNum;
    },
    /**
     * 组织键盘点击事件冒泡到父容器
     */
    stopProp: function() {
      return false;
    }
  }
};
</script>

<style lang="less" scoped>
.keyboard {
  z-index: 9;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgb(210, 213, 219);
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 10px 0;
}
.td {
  font-family: HelveticaNeue, HelveticaNeue-Regular, HelveticaNeue-Light;
  text-align: center;
  font-size: 30px;
  width: 74px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  margin: 5px 5px;
  color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.35);
}

.td_num {
  // width: 65px;
  width: 56px;
}
.td_mid {
  width: 96px;
}

.td_nor {
  width: 84px;
  margin: 10px 4px;
}

.td_big_right {
  margin-right: 44px;
}

.td_big_left {
  margin-left: 44px;
}

.td_spec_fin {
  width: 23%;
  justify-self: flex-end;
  background-color: #09f;
  color: #fff;
}
.icon-shift,
.icon-shift-on {
  width: 28px;
  height: 15px;
  position: relative;
  top: 2px;
}

.icon-del,
.icon-del-hover {
  width: 20px;
  height: 15px;
  position: relative;
  top: 2px;
}
.td_spec_shift,
.td_spec_del {
  position: absolute;
  width: 84px;
  bottom: 70px;
  background-color: rgba(119, 136, 153, 0.5);
}
.td_spec_shift {
  left: 5px;
}
.td_spec_del {
  right: 5px;
}
/* 删除hover */
.icon-del-hover {
  display: none;
}
.td_spec_del.board_bg_spec .icon-del {
  display: none;
}
.td_spec_del.board_bg_spec .icon-del-hover {
  display: inline-template;
}

.icon-shift-on {
  display: none;
}
.td_spec_shift.on .icon-shift {
  display: none;
}
.td_spec_shift.on .icon-shift-on {
  display: inline-template;
}

.board_bg {
  box-shadow: 0 0 0 #e5e5e5;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.fnkey-wrap {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 5px;
}
.fnkey-wrap .td {
  font-size: 28px;
}

.space {
  flex: 1;
  width: auto;
}

.trans,
.done,
.space {
  width: 150px;
}

.trans {
  background: rgba(119, 136, 153, 0.5);
}

.done {
  background: #007aff;
  color: #fff;
  font-family: HelveticaNeue-Light;
}
</style>
