<template>
  <div class="progress_content">
    <template v-if="isRecord">
      <span
        v-if="recordIndex == itemIndex"
        :style="{ color: themeColor }"
        class="anticon-yyashow anticon-zanting"
        @click="stopAudio"
      ></span>
      <span
        @click="playAudio"
        v-else
        class="anticon-yyashow anticon-bofang"
        :style="{ color: themeColor }"
      ></span>
    </template>
    <template v-else>
      <span
        v-if="playAudioIndex == itemIndex"
        :style="{ color: themeColor }"
        class="anticon-yyashow anticon-zanting"
        @click="stopAudio"
      ></span>
      <span
        @click="playAudio"
        v-else
        class="anticon-yyashow anticon-bofang"
        :style="{ color: themeColor }"
      ></span>
    </template>
    <div :style="{ color: themeColor }" class="audio_progress">
      <!-- 录音 -->
      <template v-if="isRecord">
        <span
          v-if="recordIndex == itemIndex"
          class="inner_progress"
          :style="{ backgroundColor: themeColor, width: audioPercent }"
        ></span>
      </template>
      <!-- 原音 -->
      <template v-else>
        <span
          v-if="playAudioIndex == itemIndex"
          class="inner_progress"
          :style="{ backgroundColor: themeColor, width: audioPercent }"
        ></span>
      </template>
    </div>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
export default {
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    playAudioIndex() {
      return globalConfig.state.playAudioIndex;
    },
    audioPercent() {
      return globalConfig.state.audioPercent;
    },
    recordIndex() {
      return globalConfig.state.playRecordIndex;
    }
  },
  props: {
    itemIndex: {
      type: [String, Number],
      default: -1
    },
    audioSrc: {
      type: String,
      default: ''
    },
    isRecord: {
      type: Boolean,
      default: false
    },
    recordId: {
      type: String,
      default: ''
    }
  },
  methods: {
    playAudio() {
      // 如果是录音
      if (this.isRecord) {
        globalConfig.commit('playAudio', {
          src: this.audioSrc,
          index: this.itemIndex,
          type: 'record',
          recordId: this.recordId
        });
        return;
      }
      globalConfig.commit('playAudio', {
        src: this.audioSrc,
        index: this.itemIndex
      });
    },

    stopAudio() {
      globalConfig.commit('stopAudio');
    }
  }
};
</script>

<style lang="less" scoped>
.progress_content {
  position: absolute;
  top: 0;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  .anticon-yyashow {
    font-size: 25px;
    line-height: 1;
    line-height: 30px;
  }
  .audio_progress {
    flex: 2;
    height: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .inner_progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}
</style>
