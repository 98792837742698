<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">
      {{ currentItem.title }}
    </div>
    <div class="game_number text_center margin_top_20">
      {{ currentItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="video_box" v-if="imgUrl && currentItem.file_url">
        <video-play
          :hisIndex="currentItem.hisIndex"
          :src="imgUrl + currentItem.file_url"
          @videoEnd="videoEnd"
        ></video-play>
      </div>
      <div
        class="rich_text_container margin_top_20"
        v-html="currentItem.intro"
      ></div>
    </div>
    <choose-footer
      :outerIndex="currentIndex"
      @toPrev="toPrev"
      @toNext="toNext"
      :showResetImg="currentItem.showResetImg"
    ></choose-footer>
  </div>
</template>

<script>
import { saveUserGameTask } from '@/api/service';
import VideoPlay from '@/components/VideoPlay';
import ChooseFooter from '@/components/ChooseFooter';
import { showMySuccess, PUBLIC_DATA } from '@/utils/common';
import globalConfig from '@/store/global_config';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ChooseFooter, VideoPlay },
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    bookChapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgUrl,
      currentIndex: 0,
      allListData: [],
      allLength: 0,
      alreadyUsedData: []
    };
  },

  computed: {
    currentItem() {
      const defaultValue = {
        hisIndex: -1,
        file_url: ''
      };
      return this.allListData[this.currentIndex] || defaultValue;
    },
    videoProgress() {
      return globalConfig.state.videoProgress;
    }
  },

  mounted() {
    this.allLength = this.itemList.length;
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
  },
  methods: {
    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        if (!item.used) {
          newArr.push({
            ...item,
            hisIndex: Number(index),
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    // 视频播放结束
    videoEnd() {
      this.toNext();
    },

    saveUserData() {
      return saveUserGameTask({
        gameId: this.gameId,
        typeId: this.typeId,
        book_chapter_id: this.bookChapterId,
        word_choice_id: this.currentItem.id,
        user_answer: '',
        read_result: this.videoProgress
      });
      // .then(() => {
      //   setTimeout(() => {
      //     this.toNext();
      //   }, 500);
      // });
    },

    toPrev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },

    toNext() {
      const { hisIndex } = this.currentItem;
      this.saveUserData().then(() => {
        if (hisIndex + 1 >= this.itemList.length) {
          this.hasFinished();
          return;
        }
        this.currentIndex++;
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
  .video_box {
    text-align: center;
    width: 500px;
    margin: 0 auto;
  }
  .rich_text_container {
    height: 300px;
    width: 500px;
    margin: 0 auto;
    overflow: scroll;
  }
}
</style>
